<template>
	<el-container class="group-page">
		<el-aside width="260px" class="group-list-box">
			<div class="group-list-header">
				<el-input class="search-text" placeholder="搜索" v-model="searchText">
					<i class="el-icon-search el-input__icon" slot="prefix"> </i>
				</el-input>
				<el-button plain class="add-btn" icon="el-icon-plus" title="创建群聊"
					@click="onCreateGroup()"></el-button>
			</div>
			<el-scrollbar class="group-list-items">
				<div v-for="(group,index) in groupStore.groups" :key="index">
					<group-item v-show="!group.quit&&group.remark.startsWith(searchText)" :group="group"
						:active="group === groupStore.activeGroup" @click.native="onActiveItem(group,index)">
					</group-item>
				</div>
			</el-scrollbar>
		</el-aside>
		<el-container class="group-box">
			<div class="group-header" v-show="activeGroup.id">
				{{activeGroup.remark}}({{groupMembers.length}})
			</div>
			<el-scrollbar class="group-container">
				<div v-show="activeGroup.id">
					<div class="group-info">
						<div>
							<file-upload v-show="isOwner" class="avatar-uploader" :action="imageAction"
								:showLoading="true" :maxSize="maxSize" @success="onUploadSuccess"
								:fileTypes="['image/jpeg', 'image/png', 'image/jpg','image/webp']">
								<img v-if="activeGroup.headImage" :src="activeGroup.headImage" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</file-upload>
							<head-image v-show="!isOwner" class="avatar" :size="200" :url="activeGroup.headImage"
								:name="activeGroup.remark">
							</head-image>
							<el-button class="send-btn" icon="el-icon-position" type="primary"
								@click="onSendMessage()">发消息</el-button>
						</div>
						<el-form class="group-form" label-width="130px" :model="activeGroup" :rules="rules"
							ref="groupForm">
							<el-form-item label="群聊名称" prop="name">
								<el-input v-model="activeGroup.name" :disabled="!isOwner" maxlength="20"></el-input>
							</el-form-item>
							<el-form-item label="群主">
								<el-input :value="ownerName" disabled></el-input>
							</el-form-item>
							<el-form-item label="备注">
								<el-input v-model="activeGroup.remark" placeholder="群聊的备注仅自己可见"
									maxlength="20"></el-input>
							</el-form-item>
							<el-form-item label="我在本群的昵称">
								<el-input v-model="activeGroup.aliasName" placeholder="" maxlength="20"></el-input>
							</el-form-item>
							<el-form-item label="群公告">
								<el-input v-model="activeGroup.notice" :disabled="!isOwner" type="textarea"
									maxlength="1024" placeholder="群主未设置"></el-input>
							</el-form-item>
							<div>
								<el-button type="success" @click="onSaveGroup()">保存</el-button>
								<el-button type="danger" v-show="!isOwner" @click="onQuit()">退出群聊</el-button>
								<el-button type="danger" v-show="isOwner" @click="onDissolve()">解散群聊</el-button>
							</div>
						</el-form>
					</div>
					<el-divider content-position="center"></el-divider>
					<el-scrollbar style="height:200px;">
						<div class="group-member-list">
							<div v-for="(member) in groupMembers" :key="member.id">
								<group-member v-show="!member.quit" class="group-member" :member="member"
									:showDel="isOwner&&member.userId!=activeGroup.ownerId" @del="onKick"></group-member>
							</div>
							<div class="group-invite">
								<div class="invite-member-btn" title="邀请好友进群聊" @click="onInviteMember()">
									<i class="el-icon-plus"></i>
								</div>
								<div class="invite-member-text">邀请</div>
								<add-group-member :visible.sync="showAddGroupMember" :groupId="activeGroup.id" :members="groupMembers" @reload="loadGroupMembers" @close="onCloseAddGroupMember"></add-group-member>
							</div>
						</div>
					</el-scrollbar>
				</div>
			</el-scrollbar>
		</el-container>
	</el-container>
</template>


<script>
	import GroupItem from '../components/group/GroupItem';
	import FileUpload from '../components/common/FileUpload';
	import GroupMember from '../components/group/GroupMember.vue';
	import AddGroupMember from '../components/group/AddGroupMember.vue';
	import HeadImage from '../components/common/HeadImage.vue';
	export default {
		name: "group",
		components: {
			GroupItem,
			GroupMember,
			FileUpload,
			AddGroupMember,
			HeadImage
		},
		data() {
			return {
				searchText: "",
				maxSize: 5 * 1024 * 1024,
				activeGroup: {},
				groupMembers: [],
				showAddGroupMember: false,
				rules: {
					name: [{
						required: true,
						message: '请输入群聊名称',
						trigger: 'blur'
					}]
				}
			};
		},
		methods: {
			onCreateGroup() {
				this.$prompt('请输入群聊名称', '创建群聊', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请输入群聊名称'
				}).then((o) => {
					let userInfo = this.$store.state.userStore.userInfo;
					let data = {
						name: o.value,
						remark: o.value,
						aliasName: userInfo.name,
						ownerId: userInfo.id
					}
					this.$http({
						url: `/group/create?groupName=${o.value}`,
						method: 'post',
						data: data
					}).then((group) => {
						this.$store.commit("addGroup", group);
					})
				})
			},
			onActiveItem(group, index) {
				this.$store.commit("activeGroup", index);
				// store数据不能直接修改，所以深拷贝一份内存
				this.activeGroup = JSON.parse(JSON.stringify(group));
				// 重新加载群成员
				this.loadGroupMembers();
			},
			onInviteMember() {
				// 确保在显示弹窗前已经加载了群成员数据
				if (!this.groupMembers || this.groupMembers.length === 0) {
					this.loadGroupMembers().then(() => {
						this.$nextTick(() => {
							// 延迟显示弹窗，确保数据已准备好
							setTimeout(() => {
								this.showAddGroupMember = true;
							}, 50);
						});
					});
				} else {
					// 已有群成员数据，直接显示弹窗
					this.$nextTick(() => {
						this.showAddGroupMember = true;
					});
				}
			},
			onCloseAddGroupMember() {
				this.$nextTick(() => {
					this.showAddGroupMember = false;
				});
			},
			onUploadSuccess(data) {
				this.activeGroup.headImage = data.originUrl;
				this.activeGroup.headImageThumb = data.thumbUrl;
			},
			onSaveGroup() {
				this.$refs['groupForm'].validate((valid) => {
					if (valid) {
						let vo = this.activeGroup;
						this.$http({
							url: "/group/modify",
							method: "put",
							data: vo
						}).then((group) => {
							this.$store.commit("updateGroup", group);
							this.$message.success("修改成功");
						})
					}
				});
			},
			onDissolve() {
				this.$confirm('确认要解散群聊吗?', '确认解散?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `/group/delete/${this.activeGroup.id}`,
						method: 'delete'
					}).then(() => {
						this.$message.success(`群聊'${this.activeGroup.name}'已解散`);
						this.$store.commit("removeGroup", this.activeGroup.id);
						this.reset();
					});
				})

			},
			onKick(member) {
				this.$confirm(`确定将成员'${member.aliasName}'移出群聊吗？`, '确认移出?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `/group/kick/${this.activeGroup.id}`,
						method: 'delete',
						params: {
							userId: member.userId
						}
					}).then(() => {
						this.$message.success(`已将${member.aliasName}移出群聊`);
						member.quit = true;
					});
				})

			},
			onQuit() {
				this.$confirm('退出群聊后将不再接受群里的消息，确认退出吗？', '确认退出?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `/group/quit/${this.activeGroup.id}`,
						method: 'delete'
					}).then(() => {
						this.$store.commit("removeGroup", this.activeGroup.id);
						this.$store.commit("removeGroupChat", this.activeGroup.id);
						this.reset();
					});
				})

			},
			onSendMessage() {
				let chat = {
					type: 'GROUP',
					targetId: this.activeGroup.id,
					showName: this.activeGroup.remark,
					headImage: this.activeGroup.headImage,
				};
				this.$store.commit("openChat", chat);
				this.$store.commit("activeChat", 0);
				this.$router.push("/home/chat");
			},
			loadGroupMembers() {
				return this.$http({
					url: `/group/members/${this.activeGroup.id}`,
					method: "get"
				}).then((members) => {
					this.groupMembers = members;
					return members;
				})
			},
			reset() {
				this.activeGroup = {};
				this.groupMembers = [];
			}
		},
		computed: {
			groupStore() {
				return this.$store.state.groupStore;
			},
			ownerName() {
				let member = this.groupMembers.find((m) => m.userId == this.activeGroup.ownerId);
				return member && member.aliasName;
			},
			isOwner() {
				return this.activeGroup.ownerId == this.$store.state.userStore.userInfo.id;
			},
			imageAction() {
				return `/image/upload`;
			}
		}
	}
</script>

<style lang="scss">
	.group-page {
		.group-list-box {
			display: flex;
			flex-direction: column;
			border: none;
			background: white;
			box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			margin-right: 16px;
			transition: all 0.3s ease;
			overflow: hidden;

			.group-list-header {
				height: 66px;
				display: flex;
				align-items: center;
				padding: 5px 12px;
				background-color: #f5f7fa;
				position: relative;
				
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 5%;
					width: 90%;
					height: 1px;
					background: rgba(0, 0, 0, 0.05);
				}
				
				.el-input__inner {
					border-radius: 20px !important;
					transition: all 0.3s ease;
					border: 1px solid #e6e6e6;
					background-color: white;
					
					&:focus {
						box-shadow: 0 0 0 2px rgba(220, 223, 230, 0.3);
					}
				}
				
				.add-btn {
					padding: 10px !important;
					margin: 5px;
					font-size: 20px;
					color: #409EFF;
					border: 1px solid #DCDFE6;
					background-color: white;
					border-radius: 50%;
					transition: all 0.3s ease;
					
					&:hover {
						background-color: #ecf5ff;
						color: #66b1ff;
						border-color: #c6e2ff;
					}
					
					&:active {
						color: #3a8ee6;
					}
				}
			}

			.group-list-items {
				flex: 1;
				margin: 5px;
				background: white;
				border-radius: 0 0 8px 8px;
				padding: 5px;
			}
		}

		.group-box {
			display: flex;
			flex-direction: column;
			border: none;
			box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			overflow: hidden;
			transition: all 0.3s ease;
			flex: 1;
			
			.group-header {
				padding: 3px;
				height: 66px;
				line-height: 66px;
				font-size: 20px;
				font-weight: 600;
				text-align: center;
				background-color: #f5f7fa;
				color: #303133;
				border: none;
				position: relative;
				
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 5%;
					width: 90%;
					height: 1px;
					background: rgba(0, 0, 0, 0.05);
				}
			}

			.group-container {
				padding: 20px;
				flex: 1;
				background-color: white;
				
				.group-info {
					display: flex;
					padding: 20px 25px;
					background-color: #f9f9f9;
					border-radius: 8px;
					box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
					transition: all 0.3s ease;
					position: relative;
					overflow: hidden;
					
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 3px;
						background-color: #409EFF;
					}
					
					&:hover {
						box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
						transform: translateY(-2px);
					}
					
					.group-form {
						flex: 1;
						padding-left: 40px;
						max-width: 700px;
						
						.el-form-item {
							margin-bottom: 20px;
							
							.el-input__inner {
								border-radius: 8px;
								transition: all 0.3s ease;
								border: 1px solid #DCDFE6;
								
								&:focus {
									box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.15);
									border-color: #409EFF;
								}
							}
							
							.el-textarea__inner {
								border-radius: 8px;
								transition: all 0.3s ease;
								border: 1px solid #DCDFE6;
								min-height: 80px;
								
								&:focus {
									box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.15);
									border-color: #409EFF;
								}
							}
						}
						
						.el-button {
							border-radius: 8px;
							padding: 10px 20px;
							transition: all 0.3s ease;
							font-weight: 500;
							
							&.el-button--success {
								background-color: #67C23A;
								border: none;
								box-shadow: 0 2px 6px rgba(103, 194, 58, 0.2);
								
								&:hover {
									background-color: #85ce61;
									transform: translateY(-2px);
									box-shadow: 0 4px 12px rgba(103, 194, 58, 0.3);
								}
								
								&:active {
									transform: translateY(-1px);
								}
							}
							
							&.el-button--danger {
								background-color: #F56C6C;
								border: none;
								box-shadow: 0 2px 6px rgba(245, 108, 108, 0.2);
								
								&:hover {
									background-color: #f78989;
									transform: translateY(-2px);
									box-shadow: 0 4px 12px rgba(245, 108, 108, 0.3);
								}
								
								&:active {
									transform: translateY(-1px);
								}
							}
						}
					}

					.avatar-uploader {
						text-align: left;

						.el-upload {
							border: 2px dashed #DCDFE6 !important;
							border-radius: 8px;
							cursor: pointer;
							position: relative;
							overflow: hidden;
							transition: all 0.3s ease;
							background-color: #fff;
							
							&:hover {
								border-color: #409EFF !important;
								box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
								transform: translateY(-2px);
							}
						}

						.avatar-uploader-icon {
							font-size: 28px;
							color: #409EFF;
							width: 200px;
							height: 200px;
							line-height: 200px;
							text-align: center;
							transition: all 0.3s ease;
							
							&:hover {
								color: #66b1ff;
								transform: scale(1.05);
							}
						}

						.avatar {
							width: 200px;
							height: 200px;
							display: block;
							border-radius: 8px;
							box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
							transition: all 0.3s ease;
							object-fit: cover;
							
							&:hover {
								box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
								transform: scale(1.02);
							}
						}
					}

					.send-btn {
						margin-top: 25px;
						background-color: #409EFF;
						border: none;
						border-radius: 8px;
						padding: 10px 20px;
						box-shadow: 0 2px 6px rgba(64, 158, 255, 0.2);
						transition: all 0.3s ease;
						font-weight: 500;
						
						i {
							margin-right: 5px;
						}
						
						&:hover {
							background-color: #66b1ff;
							box-shadow: 0 4px 12px rgba(64, 158, 255, 0.3);
							transform: translateY(-2px);
						}
						
						&:active {
							transform: translateY(-1px);
						}
					}
				}

				.group-member-list {
					padding: 20px 25px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					font-size: 16px;
					text-align: center;
					background-color: #f9f9f9;
					border-radius: 8px;
					box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
					margin-top: 25px;
					transition: all 0.3s ease;
					position: relative;
					
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 3px;
						background-color: #409EFF;
					}
					
					&:hover {
						box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
						transform: translateY(-2px);
					}

					.group-member {
						margin-right: 20px;
						margin-bottom: 15px;
						transition: all 0.3s ease;
						
						&:hover {
							transform: translateY(-3px);
						}
					}

					.group-invite {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 60px;
						margin-right: 20px;
						margin-bottom: 15px;
						transition: all 0.3s ease;
						
						&:hover {
							transform: translateY(-3px);
						}

						.invite-member-btn {
							width: 100%;
							height: 60px;
							line-height: 60px;
							border: #DCDFE6 solid 1px;
							color: #409EFF;
							font-size: 24px;
							cursor: pointer;
							box-sizing: border-box;
							border-radius: 8px;
							transition: all 0.3s ease;

							&:hover {
								background-color: #ecf5ff;
								border-color: #409EFF;
								color: #66b1ff;
							}
							
							&:active {
								color: #3a8ee6;
							}
						}

						.invite-member-text {
							font-size: 14px;
							text-align: center;
							width: 100%;
							height: 30px;
							line-height: 30px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							color: #606266;
							margin-top: 8px;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
	
	.el-divider {
		margin: 20px 0;
		
		.el-divider__text {
			background-color: white;
			color: #409EFF;
			font-weight: 500;
			padding: 0 15px;
		}
	}
	
	// 全局过渡动效
	.el-scrollbar {
		transition: all 0.3s ease;
		
		&:hover {
			.el-scrollbar__thumb {
				opacity: 0.8;
			}
		}
	}
	
	.el-scrollbar__thumb {
		background-color: #DCDFE6;
		border-radius: 20px;
		opacity: 0.4;
		transition: all 0.3s ease;
		
		&:hover {
			background-color: #C0C4CC;
			opacity: 1;
		}
	}
	
	// 确认弹窗样式优化
	.el-message-box {
		border-radius: 8px;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
		overflow: hidden;
		
		.el-message-box__header {
			background-color: #f5f7fa;
			padding: 15px 20px;
			
			.el-message-box__title {
				color: #303133;
				font-weight: 600;
			}
		}
		
		.el-message-box__content {
			padding: 20px;
		}
		
		.el-message-box__btns {
			padding: 10px 20px 20px;
			
			.el-button {
				border-radius: 8px;
				padding: 10px 20px;
				font-weight: 500;
				transition: all 0.3s ease;
			}
		}
	}
	
	// 成功消息提示优化
	.el-message--success {
		background-color: #f0f9eb;
		border-color: #e1f3d8;
		box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 10px 16px;
		
		.el-message__content {
			color: #67c23a;
		}
		
		.el-icon-success {
			color: #67c23a;
		}
	}
	
	// 警告消息提示优化
	.el-message--warning {
		background-color: #fdf6ec;
		border-color: #faecd8;
		box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 10px 16px;
		
		.el-message__content {
			color: #e6a23c;
		}
		
		.el-icon-warning {
			color: #e6a23c;
		}
	}
	
	// 错误消息提示优化
	.el-message--error {
		background-color: #fef0f0;
		border-color: #fde2e2;
		box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 10px 16px;
		
		.el-message__content {
			color: #f56c6c;
		}
		
		.el-icon-error {
			color: #f56c6c;
		}
	}
</style>
